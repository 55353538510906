// icon:flower | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

export default function Mensenlinq(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 49 39"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
        <path d="M38.31 18.814c-1.82 0-3.413.758-4.855 2.048-2.124-3.11-5.234-5.386-9.18-5.386-3.489 0-6.675 1.972-8.95 5.538-1.518-1.366-3.263-2.2-5.235-2.2-9.559 0-9.71 17.98-9.71 18.738v1.214h2.35v-1.214c0-.152.228-16.386 7.359-16.386 1.441 0 2.882.758 4.02 1.972-1.213 2.58-1.896 5.614-1.896 8.42 0 4.4 1.745 7.208 4.552 7.208 1.29 0 2.351-.607 3.034-1.745.531-.91.834-2.124.834-3.566 0-3.338-1.365-7.586-3.64-10.62 1.592-2.883 4.096-5.007 7.282-5.007s5.69 2.048 7.51 4.779C29.51 25.566 28.07 29.737 28.07 33c0 1.593.303 2.959.986 3.945.759 1.214 1.973 1.82 3.49 1.82 1.517 0 2.73-.758 3.565-2.2.607-1.137.987-2.73.987-4.475 0-2.883-.835-6.221-2.352-9.18 1.062-1.062 2.276-1.669 3.641-1.669 5.083 0 7.662 5.462 7.662 16.31v1.215H48.4v-1.214c-.076-12.442-3.414-18.738-10.09-18.738zM18.36 33.455c0 .986-.152 1.82-.456 2.352-.227.38-.53.607-.986.607-1.593 0-2.2-2.58-2.2-4.78 0-1.896.38-4.172 1.138-6.22 1.593 2.427 2.504 5.614 2.504 8.041zm15.703 1.973c-.455.834-1.062.986-1.441.986-.683 0-1.138-.228-1.442-.759-.379-.607-.607-1.517-.607-2.655 0-2.503.987-5.69 2.58-8.117 1.062 2.351 1.593 4.93 1.593 7.207-.076 1.365-.304 2.579-.683 3.338zm-9.634-21.773c3.641 0 6.524-2.958 6.524-6.524 0-3.641-2.959-6.524-6.524-6.524-3.566 0-6.525 2.959-6.525 6.524a6.476 6.476 0 006.525 6.524zm0-10.696c2.275 0 4.172 1.896 4.172 4.172s-1.897 4.172-4.172 4.172c-2.276 0-4.173-1.896-4.173-4.172s1.897-4.172 4.173-4.172zm-13.352 14.11a5.601 5.601 0 005.614-5.614 5.601 5.601 0 00-5.614-5.614 5.601 5.601 0 00-5.614 5.614c0 3.11 2.58 5.614 5.614 5.614zm0-8.876c1.745 0 3.262 1.441 3.262 3.262a3.238 3.238 0 01-3.262 3.262c-1.745 0-3.262-1.441-3.262-3.262 0-1.82 1.517-3.262 3.262-3.262zm26.627 8.876a5.601 5.601 0 005.614-5.614 5.601 5.601 0 00-5.614-5.614 5.601 5.601 0 00-5.613 5.614c0 3.11 2.579 5.614 5.613 5.614zm0-8.876c1.745 0 3.263 1.441 3.263 3.262a3.238 3.238 0 01-3.263 3.262 3.238 3.238 0 01-3.262-3.262c0-1.82 1.518-3.262 3.262-3.262z"/>
    </svg>
  );
}
